import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"
import Resume from "../images/Resume.pdf"

const Container = styled.div`
  min-width: 95vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Div = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 40%;
  padding-top: 2%;
`

const P = styled.p`
  font-size: 2vh;
  color: black;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    border-bottom: 2px solid black;
  }

  &,
  &::after {
    transition: all 0.07s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`
const Navbar = ({ scroll }) => {
  function scrollTo(id) {
    scroll.scrollTo(document.querySelector(id))
  }

  return (
    <>
      <Container>
        <Div>
          <P onClick={() => scrollTo("#CaseStudies")}>Case Studies</P>
          <P onClick={() => scrollTo("#AboutMe")}>About</P>
          <a href={Resume}>
            <P>Resume</P>
          </a>
          <a href={"https://www.linkedin.com/in/mohammad-baqer/"}>
            <P>LinkedIn</P>
          </a>
          <a href={"https://github.com/mtbaqer"}>
            <P>GitHub</P>
          </a>
        </Div>
      </Container>
    </>
  )
}
export default Navbar
