import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import Navbar from "./Navbar"

const Container = styled.div`
  min-width: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
`

const Name = styled.h2`
  font-size: 10vh;
  font-family: "Playfair Display";
  font-weight: 900;
  padding-left: 15vh;
  position: absolute;
`

const LandingIntro = ({ scroll }) => {
  const [shade, setShade] = useState(0)

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", arg => {
        const newHeight = window.innerHeight / 4
        const newShade = Math.round((255 / newHeight) * arg.scroll.y)
        setShade(newShade)
      })
    }
  }, [scroll])

  return (
    <>
      <Container>
        <Navbar scroll={scroll} />
        <Name
          style={{
            color: `rgba(${shade}, ${shade}, ${shade})`,
            opacity: 0.8,
          }}
        >
          Mohammad Baqer
        </Name>
      </Container>
    </>
  )
}

export default LandingIntro
