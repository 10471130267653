import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ScrollAnim from "rc-scroll-anim"
import TweenOne from "rc-tween-one"
import { Element } from "react-scroll"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Resume from "../images/Resume.pdf"

const ScrollOverPack = ScrollAnim.OverPack

const Container = styled.div`
  min-width: 100vh;
  min-height: 105vh;
  padding-left: 15vh;
  padding-right: 15vh;
  padding-top: 15vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  max-width: 60%;
`
const Title = styled.h2`
  font-size: 10vh;
  font-family: "Playfair Display";
  font-weight: 900;
  color: white;
`

const P = styled.div`
  color: white;
  font-family: "poppins";
  font-size: 2vh;
  line-height: normal;
`

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const RTitle = styled.h2`
  font-size: 7vh;
  font-family: "Playfair Display";
  font-weight: 900;
  color: white;
  text-decoration: underline;
  margin-top: 5%;
  transition: all 0.05s ease-in-out;
`

const ArrowContainer = styled.span`
  color: #6ed8ed;
  margin-left: -1px;
  font-family: Arial;
`

const About = ({ scroll }) => {
  const data = useStaticQuery(graphql`
    query {
      mohammad: file(relativePath: { eq: "MohammadPizza2.jpg" }) {
        childImageSharp {
          fixed(width: 494, height: 789, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (scroll) {
      scroll.on("scroll", arg => {
        const newOpacity =
          (arg.scroll.y - window.innerHeight * 1.9) / (window.innerHeight / 2)
        setOpacity(newOpacity)
      })
    }
  }, [scroll])

  return (
    <>
      <Element name="AboutMe" id="AboutMe" />

      <Container style={{ backgroundColor: "black", opacity }}>
        <Text>
          <Title>
            About Me<span style={{ color: "yellow" }}>.</span>
          </Title>
          <P>
            I'm a full-stack engineer originally from Kuwait currently living in
            the US. I currently work as a Senior Software Engineer for the Kuwaiti Ministry of Foreign Affairs, 
            working remotely from the city of Philadelphia.
            <br /> <br />I simply like to build things. From{" "}
            <a
              style={{ color: "white" }}
              href="https://saydontsay.netlify.app/"
            >
              online games
            </a>{" "}
            to full fledged{" "}
            <a
              style={{ color: "white" }}
              href="https://www.baqer.io/ZodCaseStudy"
            >
              mobile apps
            </a>
            . My latest project is a VS Code extension that has 10k+ installs and 300 stars on GitHub,{" "}
            <a
              style={{ color: "white" }}
              href="https://marketplace.visualstudio.com/items?itemName=MohammadBaqer.better-folding"
            >
              check it out
            </a>
            .
            <br /> <br />
          </P>
          <LinksContainer>
            <RTitle>
              <a style={{ color: "white" }} href={Resume}>
                Resume <ArrowContainer>→</ArrowContainer>
              </a>
            </RTitle>
            <RTitle>
              <a
                style={{ color: "white" }}
                href={"https://www.linkedin.com/in/mohammad-baqer"}
              >
                LinkedIn <ArrowContainer>→</ArrowContainer>
              </a>
            </RTitle>
            <RTitle>
              <a style={{ color: "white" }} href={"https://github.com/mtbaqer"}>
                GitHub <ArrowContainer>→</ArrowContainer>
              </a>
            </RTitle>
          </LinksContainer>
        </Text>
        <Img fixed={data.mohammad.childImageSharp.fixed} />
      </Container>
    </>
  )
}
export default About
