import React, { useEffect } from "react"
import styled from "styled-components"
import { Element } from "react-scroll"
import DegreePlannerPreview from "./DegreePlannerPreview"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ZodPreview from "./ZodPreview"
import WathayefPreview from "./WathayefPreview"

const CaseStudies = ({ scroll: locoScroll }) => {
  useEffect(() => {
    if (locoScroll) {
      gsap.registerPlugin(ScrollTrigger)

      const scroller = document.querySelector("[data-scroll-container]")

      // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
      locoScroll.on("scroll", ScrollTrigger.update)

      // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
      ScrollTrigger.scrollerProxy(scroller, {
        scrollTop(value) {
          return arguments.length
            ? locoScroll.scrollTo(value, 0, 0)
            : locoScroll.scroll.instance.scroll.y
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          }
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: scroller.style.transform ? "transform" : "fixed",
      })

      ScrollTrigger.defaults({
        scroller: scroller,
      })

      const horizontalSections = gsap.utils.toArray(".horizontal")

      horizontalSections.forEach(function(sec, i) {
        var thisPinWrap = sec.querySelector(".pin-wrap")
        var thisAnimWrap = thisPinWrap.querySelector(".animation-wrap")

        var getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth)

        gsap.fromTo(
          thisAnimWrap,
          {
            x: () =>
              thisAnimWrap.classList.contains("to-right") ? 0 : getToValue(),
          },
          {
            x: () =>
              thisAnimWrap.classList.contains("to-right") ? getToValue() : 0,
            ease: "none",
            scrollTrigger: {
              trigger: sec,
              scroller: scroller,
              start: "top top",
              end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
              pin: thisPinWrap,
              invalidateOnRefresh: true,
              anticipatePin: 1,
              scrub: true,
              //markers: true
            },
          }
        )
      })

      function updateLocoScroll() {
        locoScroll.update()
      }

      // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
      ScrollTrigger.addEventListener("refresh", updateLocoScroll)

      // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
      ScrollTrigger.refresh()

      return ScrollTrigger.removeEventListener("refresh", updateLocoScroll)
    }
  }, [locoScroll])

  return (
    <>
      <Element name="CaseStudies" id="CaseStudies" />
      <section className="horizontal">
        <div className="pin-wrap">
          <div className="animation-wrap to-right">
            <ZodPreview />
            <DegreePlannerPreview />
            <WathayefPreview />
          </div>
        </div>
      </section>
    </>
  )
}

export default CaseStudies
