import React from "react"
import DegreePlannerStudy from "./DegreePlannerStudy"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ZodPreview = () => {
  const data = useStaticQuery(graphql`
    query {
      frame: file(relativePath: { eq: "Frames.png" }) {
        childImageSharp {
          fixed(width: 1022, height: 642, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <CaseContainer>
      <Link to="ZodCaseStudy">
        <SubContainer className="kido">
          <Title>ZOD APP</Title>
          <ImageContainer>
            <Img loading="eager" fixed={data.frame.childImageSharp.fixed} />
          </ImageContainer>
        </SubContainer>
        <CaseStudyButton className="kid">
          Case Study &nbsp;
          <img style={{ margin: "auto" }} src="/images/ForwardWhite.svg" />
        </CaseStudyButton>
      </Link>
    </CaseContainer>
  )
}

const CaseContainer = styled.div`
  position: relative;
  width: 390px;
  height: 532px;
  margin-left: 260px;
  margin-top: 7%;

  &:hover .kido {
    transform: scale(1.02);
  }

  &:hover .kid {
    margin-right: -12%;
  }
`
const SubContainer = styled.div`
  transition: all 0.2s ease-in-out;
  width: 390px;
  height: 532px;
  background-color: #171566;
  border-radius: 5px;
  display: flex;
`

const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;
  padding-left: 40%;
  border-radius: 5px;
`

const CaseStudyButton = styled.div`
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px #4c69ff4c;
  background-color: #4c69ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -10%;
  margin-bottom: 20%;
  color: white;
  font-family: "poppins";
  font-size: 2vh;
  padding: 20px 15px 20px 15px;
  transition: all 0.2s ease-in-out;
`

const Title = styled.h1`
  font-size: 50px;
  font-family: "Playfair Display";
  font-weight: 600;
  position: absolute;
  bottom: 30%;
  left: -22%;
  text-decoration: none;
  color: white;
`

export default ZodPreview
