import React, { useEffect, useState } from "react"
import "./index.css"
import LandingIntro from "../components/LandingIntro"
import CaseStudies from "../components/CaseStudies"
import About from "../components/About"
import ParticlesBG from "../components/ParticlesBG"
import "./locomotive-scroll.css"
import { Helmet } from "react-helmet"
import BuyScreen from "../components/BuyScreen"
import { Media } from "gatsby-plugin-fresnel"

const IndexPage = () => {
  const [scroll, setScroll] = useState()

  useEffect(() => {
    let s
    import("locomotive-scroll").then(locomotiveModule => {
      const scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
      })
      setScroll(scroll)
      s = scroll
    })

    function destroy() {
      if (s) {
        s.destroy()
      }
    }

    return destroy
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mohammad Baqer</title>
      </Helmet>
      <Media lessThan="lg">
        <ParticlesBG black />
        <BuyScreen />
      </Media>
      <Media greaterThanOrEqual="lg">
        <ParticlesBG scroll={scroll} />
        <div data-scroll-container>
          <LandingIntro scroll={scroll} />
          <CaseStudies scroll={scroll} />
          <About scroll={scroll} />
        </div>
      </Media>
    </>
  )
}
export default IndexPage
