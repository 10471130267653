import React from "react"
import DegreePlannerStudy from "./DegreePlannerStudy"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

const WathayefPreview = () => {
  return (
    <CaseContainer>
      <SubContainer className="kido">
        <Title>
          COMING <br />
          <div style={{ paddingLeft: 155 }}>SOON</div>
        </Title>
      </SubContainer>
      {/* <CaseStudyButton className="kid">
        Work in Progress &nbsp;
        <img style={{ margin: "auto" }} src="/images/ForwardP.svg" />
      </CaseStudyButton> */}
    </CaseContainer>
  )
}

const CaseContainer = styled.div`
  position: relative;
  width: 390px;
  height: 532px;
  margin-left: 260px;
  margin-top: 7%;

  &:hover .kido {
    transform: scale(1.02);
  }

  &:hover .kid {
    margin-right: -12%;
  }
`
const SubContainer = styled.div`
  transition: all 0.2s ease-in-out;
  width: 390px;
  height: 532px;
  border-radius: 20px;
  background: linear-gradient(rgb(108, 70, 171) 0%, rgb(61, 32, 108) 100%);
`

const Title = styled.h1`
  font-size: 50px;
  font-family: "Playfair Display";
  font-weight: 600;
  position: absolute;
  bottom: 30%;
  left: -37%;
  text-decoration: none;
  color: white;
`

const CaseStudyButton = styled.div`
  border-radius: 12px;
  color: rgb(100, 63, 159);
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -10%;
  margin-bottom: 20%;

  font-family: "poppins";
  font-size: 2vh;
  padding: 20px 15px 20px 15px;
  transition: all 0.2s ease-in-out;
`

export default WathayefPreview
