import React from "react"
import DegreePlannerStudy from "./DegreePlannerStudy"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const DegreePlannerPreview = () => {
  const data = useStaticQuery(graphql`
    query {
      frame: file(relativePath: { eq: "Frame12.png" }) {
        childImageSharp {
          fixed(width: 1022, height: 642, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <CaseContainer>
      <Link to="DegreePlannerCaseStudy">
        <SubContainer className="kido">
          <Title>
            DEGREE <br />
            <div style={{ marginRight: -175 }}>PLANNER</div>
          </Title>
          <ImageContainer>
            <Img loading="eager" fixed={data.frame.childImageSharp.fixed} />
          </ImageContainer>
        </SubContainer>
        <CaseStudyButton className="kid">
          Case Study &nbsp;
          <img style={{ margin: "auto" }} src="/images/Forward.svg" />
        </CaseStudyButton>
      </Link>
    </CaseContainer>
  )
}

const CaseContainer = styled.div`
  position: relative;
  width: 390px;
  height: 532px;
  margin-left: 260px;

  &:hover .kido {
    transform: scale(1.02);
  }

  &:hover .kid {
    margin-right: -12%;
  }
`
const SubContainer = styled.div`
  transition: all 0.2s ease-in-out;
  width: 390px;
  height: 532px;
  background-color: #800000;
`
const Title = styled.h1`
  font-size: 50px;
  font-family: "Playfair Display";
  font-weight: 600;
  position: absolute;
  bottom: 30%;
  left: -31%;
  text-decoration: none;
  color: white;
`

const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;
  padding-left: 50%;
`

const CaseStudyButton = styled.div`
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -10%;
  margin-bottom: 20%;
  color: black;
  font-family: "poppins";
  font-size: 2vh;
  padding: 20px 15px 20px 15px;
  transition: all 0.2s ease-in-out;
`

export default DegreePlannerPreview
